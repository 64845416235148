<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <my-route-is-logo />
        <h2 class="brand-text text-primary ml-1">
          MyRouteIs
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to MyRouteIs! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!--          <b-alert-->
          <!--            variant="primary"-->
          <!--            show-->
          <!--          >-->
          <!--            <div class="alert-body font-small-2">-->
          <!--              <p>-->
          <!--                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>-->
          <!--              </p>-->
          <!--              <p>-->
          <!--                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>-->
          <!--              </p>-->
          <!--            </div>-->
          <!--            <feather-icon-->
          <!--              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"-->
          <!--              icon="HelpCircleIcon"-->
          <!--              size="18"-->
          <!--              class="position-absolute"-->
          <!--              style="top: 10; right: 10;"-->
          <!--            />-->
          <!--          </b-alert>-->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!--          <div class="divider my-2">-->
          <!--            <div class="divider-text">-->
          <!--              or-->
          <!--            </div>-->
          <!--          </div>-->

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <!--            <b-button-->
            <!--              variant="facebook"-->
            <!--              href="javascript:void(0)"-->
            <!--            >-->
            <!--              <feather-icon icon="FacebookIcon" />-->
            <!--            </b-button>-->
            <!--            <b-button-->
            <!--              variant="twitter"-->
            <!--              href="javascript:void(0)"-->
            <!--            >-->
            <!--              <feather-icon icon="TwitterIcon" />-->
            <!--            </b-button>-->
            <!--            <b-button-->
            <!--              variant="google"-->
            <!--              href="javascript:void(0)"-->
            <!--            >-->
            <!--              <feather-icon icon="MailIcon" />-->
            <!--            </b-button>-->
            <b-button
              @click="handleClickLogin"
            >
              Google Log in
            </b-button>
            <b-button
              @click="handleClickSignIn"
            >
              Google Sign in
            </b-button>

          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyRouteIsLogo from '@/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    MyRouteIsLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      isInit: false,
      isSignIn: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    const that = this
    const checkGauthLoad = setInterval(() => {
      that.isInit = that.$gAuth.isInit
      that.isSignIn = that.$gAuth.isAuthorized
      if (that.isInit) clearInterval(checkGauthLoad)
    }, 1000)
  },
  methods: {
    async handleClickUpdateScope() {
      const option = new window.gapi.auth2.SigninOptionsBuilder()
      option.setScope('email https://www.googleapis.com/auth/drive.file')
      const googleUser = this.$gAuth.GoogleAuth.currentUser.get()
      try {
        await googleUser.grant(option)
        console.log('success')
      } catch (error) {
        console.error(error)
      }
    },
    handleClickLogin() {
      this.$gAuth
        .getAuthCode()
        .then(authCode => {
          // on success
          console.log('authCode', authCode)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn()
        if (!googleUser) {
          return null
        }
        console.log('googleUser', googleUser)
        console.log('getId', googleUser.getId())
        console.log('getBasicProfile', googleUser.getBasicProfile())
        console.log('getAuthResponse', googleUser.getAuthResponse())
        console.log(
          'getAuthResponse',
          this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse(),
        )
        this.isSignIn = this.$gAuth.isAuthorized
      } catch (error) {
        // on fail do something
        console.error(error)
        return null
      }
    },
    async handleClickSignOut() {
      try {
        await this.$gAuth.signOut()
        this.isSignIn = this.$gAuth.isAuthorized
        console.log('isSignIn', this.$gAuth.isAuthorized)
      } catch (error) {
        console.error(error)
      }
    },
    handleClickDisconnect() {
      window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            client_id: process.env.VUE_APP_API_CLIENT_ID,
            client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
            grant_type: 'password',
            username: this.userEmail,
            password: this.password,
          })
            .then(response => {
              useJwt.setToken(response.data.access_token)
              useJwt.setRefreshToken(response.data.refresh_token)

              store.dispatch('account/getUserCurrent').then(currentUser => {
                const userData = currentUser.data

                // ? This is just for demo purpose as well.
                // ? Because we are showing eCommerce app's cart items count in navbar
                // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router.replace(getHomeRouteForLoggedInUser())
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Welcome ${userData.name}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `You have successfully logged in as ${userData.roles.join()}. Now you can start to explore!`,
                      },
                    })
                  })
              })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors({ email: error.response.data.message })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
